import { gsap } from 'gsap';
import animateHero from './helpers/gsap-animation';

function initHeroMaskedAnimations() {
    const hero = document.querySelector('.hero-masked');
    if (!hero) {
        return;
    }

    const contents = hero.querySelectorAll('.hero-masked__content-wrapper');
    const image = hero.querySelector('.hero-masked__image img');

    contents.forEach((content) => {
        const timeline = gsap.timeline({ delay: 1 });
        const contentItems = Array.from(content.children);
        animateHero(timeline, contentItems, image);

        timeline.eventCallback('onStart', () => {
            hero.classList.remove('animate-pending');
        });

        timeline.eventCallback('onComplete', () => {
            contentItems.forEach((item) => {
                item.style = null; // for z-indexes
            });
        });
    });
}

initHeroMaskedAnimations();
